.wallet{
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1024;
    background-color: rgba(0,0,0,0.6);

    .close{
        cursor: pointer;
        svg{
            color: #FC2E20;  
        }
    }

    .btn{
        color: #fff !important;
        background-color: #18A558;
        border: 1px solid #18A558;
    }

    .ant-card-actions{
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border: none;
    }

    .ant-card-head{
        border: none;
    }

    .ant-input-number-handler-wrap{
        display: none !important;
    }

    .ant-notification-notice{
        z-index: 1027;
    }

    .ant-alert{
        z-index: 1027;
        position: absolute;
        right: 20px;
        top: 40px;
        transition: all 0.7s ease-in-out;
    }
}