.custom-btn{
    border: 1px solid rgba(54,134,52,1);
    min-width: 70px;
    border-radius: 7px;
    cursor: pointer;
    color: #fff;
    letter-spacing: 1px;
    background-color: rgba(54,134,52,0.9);

    &:hover{
        background-color: rgba(54,134,52,1);
    }
}

.sm-p{
    padding: 2px  10px;
}

