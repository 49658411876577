// Variables
$mobile: 425px;
$tablet: 768px;
// Variables

// Master Classes

// Fonts
.ap-flg{
    font-size: 28px;
}

.ap-fmd{
    font-size: 20px;
}

.ap-fsm{
    font-size: 16px;
}

.ap-fesm{
    font-size: 13.5px;
}

.ap-fwb{
    font-weight: 550;
}

.ap-fwmd{
    font-weight: 500;
}

.ap-fwt{
    font-weight: 400;
}

.ap-fwet{
    font-weight: normal;
}
// Fonts 

// Width
.ap-w100{
    width: 100%;
    object-fit: contain;
}

.ap-w75{
    width: 75%;
}

.ap-w70{
    width: 70%;
}

.ap-w60{
    width: 60%;
}

.ap-w40{
    width: 40%;
}

.ap-w48{
    width: 48%
}

.ap-w50{
    width: 50%;
}

.ap-w33{
    width: 33%;
}

.ap-w30{
    width: 30%;
}

.ap-w35{
    width: 35%;
}

.ap-w25{
    width: 25%;
}

.ap-w24{
    width:24%;
}
// Width

// Display
.ap-flex{
    display: flex;
    display: -webkit-flex;
}

.ap-flex-center{
    justify-content: center;
}

.ap-flex-end{
    justify-content: flex-end;
}
.ap-flex-start{
    justify-content: flex-start;
}

.ap-flex-space-bw{
    justify-content: space-between;
}

.ap-flex-space-around{
    justify-content: space-around;
}

.ap-flex-space-equal{
    justify-content: space-evenly;
}

.ap-flex-center-center{
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ap-flex-wrap{
    flex-wrap: wrap;
}
// Display

//Spacing
.ap-p5{
    padding: 5px;
}

.ap-p10{
    padding: 10px;
}

.ap-lsp{
    letter-spacing: 1px;
}
//Spacing

// colors
.ap-bgc{
    background-color: var(--primary-dark-background);
}

.ap-btn-c{
    background-color:var(--primary-light-background)!important;
color: #FFF !important;
    border-color: var(--primary-light-background)!important;
    &:hover{
        background-color:var(--primary-light-background)!important;
    }
}

.submit_right1{
    background-color:var(--primary-light-background)!important;
    color: #FFF !important;
    border-color: var(--primary-light-background)!important;
    &:hover{
        background-color:#fff !important;
        color:var(--primary-light-background)!important;
        border: 1px solid var(--primary-light-background)!important;

    }
}
.submit_right_outline{
    border: 1px solid var(--primary-light-background)!important;
    color:var(--primary-light-background)!important;
    background-color:#fff !important;
    &:hover{
        background-color:var(--primary-light-background)!important;
        color: #FFF !important;

    }
}

.css-1srmeiz{
        color: var(--primary-green)  !important;

}
.ap-btn-danger{
    background-color: #D10000 !important;
    color: #fff !important;
    border-color: #D10000 !important;
}
// colors

// Master Classes

// Media Queries
@media (max-width: $tablet) {

    // Fonts 
    .ap-flg{
        font-size: 28px;
    }
    
    .ap-fmd{
        font-size: 21px;
    }
    
    .ap-fsm{
        font-size: 13.5px;
    }
    
    .ap-fesm{
        font-size: 11.81px;
    }
}

@media (max-width: $mobile){
    
    // Fonts 
    .ap-flg{
        font-size: 24px;
    }
    
    .ap-fmd{
        font-size: 18px;
    }
    
    .ap-fsm{
        font-size: 13.5px;
    }
    
    .ap-fesm{
        font-size: 10.125px;
    }
}
// Medi

