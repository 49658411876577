.edit-btn{
    cursor: default;
    svg{
        color:  var( --primary-green);
        cursor: pointer;
    }
}

.client-btn{
    svg{
        margin-top: -5px;
    }

    // .ant-btn-primary{
    //     background-color: rgba(135,208,104,0.9) !important;
    //     border: 1px solid rgba(135,208,104,1) !important;

    //     &:hover{
    //         background-color: rgba(135,208,104,1) !important;
    //     }
    // }
}

.ant-alert{
    z-index: 1027;
    position: fixed;
    right: 20px;
    top: 40px;
    transition: all 0.7s ease-in-out;
}