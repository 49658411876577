.co-btn {

  svg {
  
  margin-top: -5px;
  
  }
  
  }
  
  
  
  .btnx {
  
  position: absolute;
  
  border: 1px solid red !important;
  
  }
  
  .btnp {
  
  justify-content: end;
  
  }
  
  .btn-danger {
  
  padding: 6px;
  
  height: 36px;
  
  width: 35px;
  
  margin: -14px;
  
  border-radius: 50px;
  
  }