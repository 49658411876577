.create-new-project{
    // border: 1px solid black;

    .custom-card{
        box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
        border-radius: 7px;
        background-color: #fff;

        .head{
            padding: 5px 10px;
            border-bottom: 1px solid rgba(0,0,0,0.5);
        }

        .custom-card2{
            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
            border-radius: 7px;
            justify-content: flex-start;
            height: fit-content;
            background-color: #fff;

            .btn{
                border-radius: 7px;
                letter-spacing: 1px;
            }

            .edit-btn{
                svg{
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}