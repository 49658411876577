.search-btn{
    svg{
        margin-top: -5px;
    }

    .ant-btn-primary{
        background-color:var(--primary-light-background) !important;
        border: 1px solid var(--primary-light-background) !important;

        &:hover{
            background-color: var(--primary-light-background) !important;;
        }
    }
}

.table-btn{
    svg{
        width: 16px;
        margin-right: 5px;
    }
    .ant-btn-primary{
        background-color: var(--primary-light-background) !important;
        border: 1px solid var(--primary-light-background) !important;

        &:hover{
            background-color: var(--primary-light-background) !important;
        }
    }
}

.ant-table-thead{
    .ant-table-cell{
    background-color: var(--primary-dark-background) !important;

        color: #fff;
    }
}