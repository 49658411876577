
.chakra-modal__footer{
    position: relative !important;
}

.chakra-modal__header{
    position: relative !important;
    box-shadow: none;
}

.chakra-select__icon-wrapper{
    display: none !important;
}

.ant-select-dropdown{
    z-index: 2022;
}