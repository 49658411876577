.new-aside{
  position: fixed;
  left: 0%;
  height: 100vh;
  background-color:var(--primary-dark-background);
}
aside{
  z-index: 1;
  background-color: var(--primary-dark-background) !important;
  position: fixed !important;
  left: 0;
  top: 0;
  // height: 100vh;
  max-height: 100vh;
  overflow-y: auto;

  ul{
    background-color: var(--primary-dark-background) !important;
    li{
      &:hover{
        background-color: var( --primary-light-background) !important;
      }
    }
    .ant-menu-item-active{
      background-color:  var( --primary-light-background) !important;
    }
   
    .ant-menu-item-selected{
      background-color:  var( --primary-light-background) !important;
      &:hover{
        background-color:  var( --primary-light-background) !important;
      }
    }
    .ant-menu-item-icon{
      color: rgba(255,255,255,1) ;
    }
    .ant-menu-title-content{
      color: rgba(255,255,255,1) ;
      
    }

    
    
    .ant-menu-submenu-open{
      background-color: var(--primary-dark-background) !important;
    }
    
  }

  .ant-menu-sub{
    background-color:  var(--primary-dark-background) !important;
  }
}

.ant-menu-item{
  a{
    text-decoration: none !important;
  }
}

aside::-webkit-scrollbar {
  width: 0.5em;
}

aside::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

aside::-webkit-scrollbar-thumb {
background-color: rgba(255,255,255,0.4);
outline: 1px solid slategrey;
border-radius: 10px;
}

header{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1024;
  box-shadow: 0 4px 4px -4px lightblue;

  .logout{
    cursor: pointer;
    width: fit-content;
    margin-top: 0;
  }
}

.cp{
  &:hover{
    color: var( --primary-green);
  }
}

footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  
}

.user{
  margin-right: 10px;
  width: fit-content;
  position: relative;
}

.ant-layout-sider-trigger{
  background-color:  var(--primary-dark-background) !important;
}

.logo {
    height: 64px;
    position: fixed;
    width: 250px;
    z-index: 1024;
    color: #fff;
    top: 0;
    left: 0;
    background-color: #fff;
  border-bottom: 1px solid rgba(0,0,0,0.3);

  }
  
.site-layout .site-layout-background {
  background: #fff;
}