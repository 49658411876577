.btn{
    color: #fff !important;
    background-color: var(--primary-light-background) !important;
    border: 1px solid var(--primary-light-background) !important;
}

.ant-card-actions{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
}

.ant-card-head{
    border: none;
}