.trello-date{
    .ant-picker{
        border: none;
        background-color: #ddd;
        .ant-picker-input{
            input::placeholder{
                color: #000;
            }
        }
        svg{
            fill: #000;
        }
    }
}