
:root {
  --primary-dark-background:#12203c;
  --primary-light-background:#374768;
  --primary-green:#4caf50;

}
body {
 
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");
body {
  font-family: "PT Sans", sans-serif;
}
.slack {
  background: #f9f9f9 !important;
}

.container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  background: yellow;
}

.darker {
  border-color: #ccc;
  background-color: #ddd;
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.container img {
  float: left;
  /* max-width: 60px; */
  width: 6%;
  margin-right: 10px;
  /* border-radius: 50%; */
}

.container img.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

.time-right {
  float: right;
  color: #aaa;
}

.time-left {
  float: left;
  color: #999;
}
.right-sidebar {
  float: left;
  min-height: 560px;
  overflow: scroll;
  background-color: #eee;
  width: 25%;
  margin-left: 4px;
}
.txtBox input {
  border: 1px solid #ccc;
  padding: 0px;
  margin-bottom: 10px;
}
.container img {
  /* height:660px !important; */

  overflow: scroll;
}
.customNav {
  /* display: block!important; */
  list-style: none;
}
.customNav a:hover {
  background-color: #72435c;
}
.customNav a {
  color: white;
}
.nav-item {
}
.nav-item:hover {
  background-color: #3a4248;
}
.activeNav {
  /* background-color: #4dbd74 !important; */
  background-color: #37b44a !important;
}
.chat-transfer-button {
  padding: 8px 0px;
}
.chat-transfer {
  margin-left: 5px;
}
.customNav li.nav-item {
  border-bottom: none;
  padding: 6px 0px;
  /* background: #66d9ff; */
  background: #022344;
  border: 0px solid #ccc;
  margin-top: 5px;
  box-shadow: 2px 2px 2px #aaa;
}

.chat-transfer-button input,
.chat-transfer-button button {
  padding: 3px;
}
.sidebar .nav-link {
  border: 1px solid #284460;
}
.sidebar li.nav-item:hover,
.sidebar li.nav-item {
  padding: 0px 0px;
}
.sidebar .nav-link:hover {
  background: #022344;
}
.sidebar .nav-link.active {
  color: #fff;
  background: #022344;
}
.sidebar-section h5 {
  border-radius: 0px;
}
.sidebar-section {
  border-radius: 0px;
}
.main .container-fluid {
  padding: 0 10px;
}
.txtBox {
  font-size: 15px;
  margin-bottom: 20px;
}
.txtBox input {
  border: 1px solid #ccc;
}
span.chat_date {
  display: inline-block;
  font-size: 13px;
}
.container b {
  font-size: 14px;
}
.btn-success {
  background-color: #37b44a;
  border-color: #37b44a;
  font-weight: bold;
  font-size: 16px;
}
.fa-user-circle {
  margin-top: 20px;
}
.search-box {
  padding: 5px;
  position: sticky;
  top: 0;
  background: #f9f9f9;
}
.nav-icon {
  margin: 0 1.5rem 0 0 !important;
}
.txtBox textarea {
  width: 80%;
}
.chatBox {
  background: none;
  position: sticky;
  background-color:  var(--primary-dark-background);
  top: 0;
  z-index: 55;
  height: 60px;
}
.btn-danger {
  background: #dd2828;
  border-color: #dd2828;
  font-weight: bold;
}
.slackSidebar {
  /* height:660px */
}
.rn-borderTopWidth-5kkj8d {
  position: sticky !important;
  bottom: 0;
}
.myTextArea {
  margin-left: 10px;
  font-size: 16px;
  line-height: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
  outline: currentcolor none medium;
  border: medium none;
  overflow: auto;
  box-shadow: none;
  resize: none;
  width: 100%;
}

@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
body {
  font-family: "Roboto", sans-serif;
}
.slack {
  background: #3f4a88;
}
.chatBox {
  background-color: #fff;
  max-height: 700px;
  min-height: 560px;
}
.txtBox {
  background-color: #fff;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  line-height: 35px;
}
.jumpNew {
  background-color: #fff;
}
.txtBox textarea {
  height: 75px;
  width: 50%;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.txtBox input.btn.btn-success {
  width: 150px;
  margin-top: -4%;
  padding: 7px 0px;
  font-size: 18px;
  margin-left: 2%;
}
.send {
  background-color: #fff;
  float: left;
  width: 200px;
}
.activeNav {
  background-color: #72435c;
}
.userName {
  width: 70%;
  padding: 10px;
  font-weight: bold;
  float: left;
}
.logout {
  width: 10%;
  float: right;
  margin-right: 5px;
  margin-top: 5px;
  margin-top: 5px;
}
.clear {
  clear: both;
}
ul.nav.customNav a {
  position: relative;
  padding-left: 60px;
}
ul.nav.customNav a .fa-user-circle {
  position: absolute;
  left: 0px;
  top: 0px;
  left: 10px;
  font-size: 30px;
}
li.nav-item {
  border-bottom: 1px solid #252e5f;
  padding: 6px 0px;
}
ul.nav.customNav li.nav-item b {
  font-size: 19px;
  margin-bottom: -1px;
  display: block;
}
span.count {
  width: 35px;
  height: 35px;
  background: #f76c6b;
  float: right;
  display: inline-block;
  line-height: 35px;
  text-align: center;
  border-radius: 5px;
}
.slackSidebar {
  /* float: left; */
  height: 95vh;
  /* width: 20%; */
  overflow-y: scroll;
  /* height: 600px; */
  scrollbar-width: thin;
}
.search-box {
  padding: 5px;
  text-align: center;
}
.top-header{
  position: fixed; 
  top: 0px; 
  z-index: 999; 
  width: 100%;
}
.chat-whole-container {
  padding-top: 35px;
}
.nav-icon {
  padding-left: 15px;
  padding-right: 5px;
  margin-top: 10px;
  display: inline-block;
}
.chat-box a {
  font-size: 18px;
  margin-top: 7px;
  color: #340d35;
}
.container {
  border: 1px solid #eeeeee !important;
  background-color: #f7f7f7 !important;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  border-left: 3px solid #3ea8d8;
}
.container b {
  font-size: 18px;
  color: #333;
  margin-right: 3px;
}
.container span {
  font-size: 16px;
  color: #555;
}
.customNav a:hover {
  background-color: transparent !important;
}
li.nav-item:hover {
  border-bottom: 1px solid #551057;
  padding: 6px 0px;
  background: #243078;
}
.sidebar-section {
  background: #fff;
  margin: 10px 0px;
  border-radius: 10px;
}
.sidebar-section h5 {
  padding: 5px 5px;
  background: #dce3e7;
  border: 1px solid #eee;
  border-radius: 10px 10px 0px 0px;
}
.sidebar-section h4,
.sidebar-section ul {
  padding: 10px 10px;
}
.sidebar-section ul li {
  font-size: 18px;
  padding: 5px 0px;
  padding-left: 16px;
  position: relative;
  list-style-type: none;
}

.sidebar-section ul li:before {
  content: "\f0da";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #3f4a88;
  font-size: 18px;
  padding-right: 0.5em;
  position: absolute;
  top: 4px;
  left: 0;
}
.sidebar {
  background: #022c55;
}
.slack {
  background: #d1d3d5 !important;
  border: 1px solid #aaa;
}

.butonColor {
  background-color: #440041;
  /* padding: 20px 0px 10px; */
  margin: 0px 0px;
  border: 0;
  /* border-bottom: 0.11px solid white; */
  width: 100%;
  border-bottom: 1px solid #5e025a;
}
.listView b {
  font-size: 13px;
  font-weight: 400;
}
.search-block {
  display: flex;
  background: #fff;
  justify-content: space-between;
}

.butonColor b,
p {
  color: white;
}
.butonColor p {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}
.butonColor span {
  margin-top: 10px;
  text-align: left;
}
.butonColor img {
  height: 50px;
  width: 50px;
}
.ChatView {
  /* background: red; */
  height: 95vh;
  margin: 0 -4%;
  border-right: 2px solid #aaa;
}
.chatview1 {
  float: left;
  width: 100%;
  overflow: auto;
  background: white;
}
.buttonLoad {
  float: right;
  margin-top: 3px;
  background: none;
  border: none;
}
.p-chat-box {
  border-bottom: 2px solid #ebedef;
  padding: 0.5rem 2rem ;
}
.chat-box {
  height: 60px;
  border-bottom: 2px solid #ebedef;
  padding: 0px 3%;
}
.chat-box p {
  float: left;
  color: var(--primary-dark-background);
  margin-top: 15px;
}
.activeBtn {
  background-color:  var(--primary-dark-background);
  /* padding: 20px 0px 10px; */
  margin: 0px 0px;
  /* border-bottom: 0.11px solid white; */
  width: 100%;
  border: 0;
}

.activeBtn b,
p {
  color: white;
}
.activeBtn p {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}
.activeBtn span {
  margin-top: 10px;
  text-align: left;
}
.activeBtn img {
  height: 50px;
  width: 50px;
}
.chatview2 {
  height: 60px;
  /* background:  var(--primary-dark-background); */
}
.inputView {
  border: 1px solid #ccc;
  background: #e6e8eb;
  /* height: 22vh; */
  /* background: aqua; */
  padding: 10px;
}
.p-inputView {
  border: 1px solid #ccc;
  background: #e6e8eb;
  /* height: 22vh; */
  /* background: aqua; */
  padding: 10px;
}
.p-inputView button{
height: 30px;
width: 30px;
} 
.chats {
  height: 67vh;
  /* background: yellow; */
  /* overflow: scroll; */
}
.o-chats {
  height: 74vh;
  background: #e8fff0;
  /* overflow: scroll; */
}
.p-chats {
  height: 67vh;
  min-width:24rem;
  /* background: yellow; */
  /* overflow: scroll; */
}
.boxView {
  width: 99%;
  height: 70px;
  background: white;
  border-radius: 5px;
  /* margin-top: 3%; */
}
.p-boxView {
  width: 99%;
  height: 70px;
  background: white;
  border-radius: 5px;
  /* margin-top: 3%; */
}
.boxView input {
  height: 60px;
  width: 86%;
  border: none;
  background: white;
  padding: 0px 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.boxView button {
  width: 35px;
  border: none;
  padding: 4px;
  margin: 0px 4px;
  background: #3ab54a;
}
.p-boxView input {
  height: 60px;
  width: 80%;
  border: none;
  background: white;
  padding: 0px 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.p-boxView button {
  width: 25px;
  border: none;
  padding: 4px;
  margin: 0px 4px;
  background: #3ab54a;
}
.p-boxView button img {
  width: 16px;
}
.boxView button img {
  width: 26px;
}
.listTextAlign {
  display: flex;
  flex-wrap: wrap;
}
.listTextAlign img {
  height: 34px;
  width: 34px;
  align-self: center;
  margin: 0px 17px;
  margin-top: 0px;
  margin-top: -5px;
}
.p-chatList {
  /* overflow-x: scroll; */
    width: 100%;
}
.orderDetail {
  height: 95vh;
  overflow: scroll;
}
.orderInner {
  height: 60px;
  background: white;
  border-left: 1px solid #ebedef;
  padding: 15px;
}
.orderInner p {
  color: black;
  text-align: center;
  font-size: 14px;
}
.InnerDetail {
}
.InnerDetail p {
  background: var( --primary-dark-background);
  padding: 7px;
  font-size: 16px;
  font-weight: bold;
}
.InnerDetail ul {
  list-style: disclosure-closed;
}
.InnerDetail li {
  margin: 7px 0px;
  font-size: 14px;
}
.InnerDetail ul {
  padding-left: 20px;
}
.btnShowAdmin {
  margin-bottom: 1rem;
  width: 100%;
  text-align: left !important;
  font-size: 18px !important;
  font-weight: bold !important;
  border-radius: none;
  background: #3aaf36;
  border: #3aaf36;
}
.adminhead {
  justify-content: space-between;
  height: 27px;
  display: flex;

  flex-wrap: wrap;
}
.adminView button {
  margin-bottom: 0px;
}
.adminView {
  margin: 16px 0px;
}
.adminView .adminhead {
  font-size: 16px;
}
.NOChat {
  background: #3aaf36;
  text-align: center;
  margin: 10px;
  padding: 10px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}
.ChatMessage {
  background: #e6e8eb;
  min-width: 10%;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  max-width: 70%;
  color: black;
}
.ChatMessage p {
  color: black;
  font-size:14px
}
.ChatImageBox {
  background: #e6e8eb;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  max-width: 75%;
  text-align: center;
  min-width: 25%;
}
.ChatImageBox img {
  height: 100px;
  width: 100px;
}
.PChatImageBox {
  background: #e6e8eb;
  padding: 8px;
  margin: 5px;
  border-radius: 8px;
  max-width: 65%;
  text-align: center;
  min-width: 15%;
}
.PChatImageBox img {
  height: 80px;
  width: 80px;
}
.date {
  font-size: 10px;
  text-align: right;
  margin-bottom: -5px;
}
.ChatImageBox p {
  margin-top: 4px;
  color: black;
  font-size: 9px;
}
.myName {
}
.rowWithoutMargin {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.userIcon {
  height: 30px;
  width: 30px;
  background: #e6e8eb;
  margin: 11px;
  border-radius: 14px;
  text-align: center;
  padding-top: 2px;
  margin-top: 17px;
}
.uploadImage {
  background: #e6e8eb;
  /* height: 100px; */
  width: 100px;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin: 0px 5px;
}
.uploadImage img {
  height: 60px;
  width: 60px;
  text-align: center;
}
.uploadImage p {
  font-size: 12px;
  color: black;
  word-break: break-all;
  margin-top: 2px;
  margin-bottom: 0px;
  white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: 200px;
}

.page {
  overflow-y: scroll;

  /* width:150px; */
  height: 72vh;
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg); /* Mozilla */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  -ms-transform: rotateX(180deg); /* IE 9+ */
  -o-transform: rotateX(180deg); /* Opera */
}
.sub {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg); /* Mozilla */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  -ms-transform: rotateX(180deg); /* IE 9+ */
  -o-transform: rotateX(180deg); /* Opera */
}
#parentDiv {
  /* width:115px;
  height:100px; */
  overflow: auto;
  border: 2px black solid;
}
.MyInputOnUpload {
  width: 100%;
  height: 60px;
  margin: 2% 0%;
}
.msgText {
  text-align: left;
  color: black;
  font-size: 14px !important;
}
.circleView {
  height: 20px;
  width: 20px;
  background: #3aaf36;
  float: right;
  margin-top: 2px;
  border-radius: 16px;
 
}
.circleView p {
  margin-top: 2px;
  font-weight: bold;
}
.adminhead img {
  height: 16px;
  width: 16px;
  margin-top: 6px;
}
.rowWithoutMarginUser {
  float: right;
  display: flex;
  /* background: red; */
  width: 100%;
  justify-content: flex-end;
}
.form-check-input {
  position: inherit;
  margin-top: 0;
  margin-left: -1.25rem;
}
.attechLi{
  background: #e6e8eb;
border-radius: 10px;
list-style: none;
margin-left: -10%;
margin-bottom: 5px;
}


.TopVIew{
  display: flex;
flex: 1;
}
.approvalText{
  margin-top: 4px;
  color: black;
  text-align: right !important;
  font-size: 9px;
}
.pagination li{
  padding: 5px 10px;
  border: 1px solid var(--primary-dark-background);
}
.pagination li.active{
  background-color: var(--primary-dark-background);
}
.pagination li.active a{
  color: white;
  }
  .modal-dialog {
    max-width: 750px!important;
    margin: 1.75rem auto;
}

.multiple-industries {
  border-radius: 5px;
  width: 100%;
  background: #fffdfd;
  height: 100%;
  text-align: center;
  transition: .6s all;
  padding: 20px;
  -webkit-box-shadow: 0 0 18px 0 rgba(0,0,0,.15);
  -moz-box-shadow: 0 0 18px 0 rgba(0,0,0,.15);
  box-shadow: 0 0 6px 0 rgba(0,0,0,.15);
  border: solid 1px #fbfbfb;
}
.multiple-industries:hover {
 background:var(--primary-dark-background);
  color: white;
}
.multiple-industries:hover .multiple-industries-icon {
 border: 4px solid #ffffff;
  background: #FFF176;
}
.multiple-industries .multiple-industries-icon {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  padding: 25px;
  text-align: center;
  border: 4px solid #37b44a;
  background: #fff;
  margin: 0 auto;
  margin-bottom: 15px;
}
.multiple-industries h4 {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 600;
  font-style: normal;
}
.multiple-industries:hover h4{
  color: white;
}
.multiple-industries span.fa{
  font-size: 40px;
}



.ordr-numbr-heding{
font-size: 16px;
margin-bottom: 10px;
  margin-top: 10px;
}
.ordr-numbr-heding .ordr-nmbr{
color: red;
}
.multpl-industri{
  border-radius: 5px;
  width: 100%;
  background: #fffdfd;
  height: 100%;
  transition: .6s all;
  padding: 10px;
  -webkit-box-shadow: 0 0 18px 0 rgba(0,0,0,.15);
  -moz-box-shadow: 0 0 18px 0 rgba(0,0,0,.15);
  box-shadow: 0 0 6px 0 rgba(0,0,0,.15);
  border: solid 1px #fbfbfb;
}
.multpl-industri-btn .btn-lg{
  padding: 6px 50px;
  font-size: 22px;
  line-height: 1.5;
  border-radius: .3rem;
  width: 100%;
  margin-bottom: 10px;
}
.multpl-industri.discrption{
margin-top: 20px;
}
.desc-heding {
  font-size: 20px;
  margin: 8px 0px;
}
.icon-dwnl{
  padding-right: 40px;
  color: blue;
  }
  .var {
background-image: var(--url);
background-size: cover;
background-repeat: no-repeat;
background-position: center;
}



.box-main{
  background: #24562b54;
  padding: 29px;
  border-radius: 8px;
  box-shadow: 0 0 18px 0 rgba(0,0,0,.15);
  position: relative;
  top: 60px;
}
.main-header-section{
border-bottom: 2px solid var(--primary-light-background);
  padding-bottom: 8px;
  padding-top: 8px;
  }
  
  .foo-bottom{
  position: fixed;
  bottom: 0;
  color: var(--primary-light-background);
  padding: 4px 4px;
  font-size: 16px;
}
.container-bx-mn{
  width: 900px;
  margin: 0 auto;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.paymentOrderDetailing:hover{
  text-decoration: underline;
  color: #243078;
  cursor: pointer;
}

