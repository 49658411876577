.login{
    background-color: #e9e9eb;
    height: 100vh;
    position: relative;

    .ant-alert{
        z-index: 1027;
        position: fixed;
        right: 20px;
        top: 40px;
        transition: all 0.7s ease-in-out;
    }

    .form__field {
        width: 100%;
        color: #a3a3a3;
        font: inherit;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
        border: 0;
        border-radius: 10px;
        outline: 0;
        padding: 22px 18px;
        margin: 10px 0;
      }

      .btn--primary {
        background: var(--primary-light-background);
        color: #fff !important;
        border-radius: 10px;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1) !important;
        padding: 12px 36px;
        border: none;
      }
      .btn--primary:hover {
        background: #6c7ff2;
      }
      .btn--primary:active {
        background: #7f8ff4;
        box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.2);
      }
}